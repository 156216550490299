@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

:root {
  --primary-color: #3f5c9b;
  --text-dark: #2E3E4E;
  --text-light: #475569;
  --extra-light: #f2f2f2;
  --white: #ffffff;
  --max-width: 1200px;
  --gradient: linear-gradient(to bottom, #3f5c9b, #5a5c6f);
  --header-gradient: linear-gradient(to bottom, #3f5c9b, #3f5c9b, #5a5c6f);

  /* Theme colors for dark mode or additional themes */
  --background-dark: #121212;
  --text-light-theme: #f2f2f2;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 1.5rem 1rem;
}

@media (max-width: 768px) {
  .car__types__container {
    padding: 0rem 1rem;
  }

  .cars__list__container {
    padding: 3rem 1rem;
  }

  .car-details-container {
    padding: 3rem 1rem;
  }
}

.section__subheader {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-light);
  letter-spacing: 1px;
}

.section__header {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--text-dark);
}

@media (max-width: 768px) {
  .section__header {
    font-size: 1.5rem;
  }

  .section__subheader {
    font-size: 1rem;
  }
}

.btn {
  padding: 0.75rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: #474fa0;
  white-space: nowrap;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
}

.btn:hover {
  background-color: #3c4286;
}

img {
  display: flex;
  width: 100%;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #333333;
}

ul {
  list-style: none;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: "Mulish", sans-serif;
  margin: 0;
  background-color: var(--white);
  line-height: 1.5
}

header {
  position: relative;
}

header::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

body,
header,
nav {
  border: none;
}

nav {
  position: fixed;
  isolation: isolate;
  width: 100%;
  z-index: 9;
}

.nav__header {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-color);
}

.nav__logo a {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--white);
}

.nav__menu__btn {
  font-size: 1.5rem;
  color: var(--white);
  cursor: pointer;
}

.nav__links {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--primary-color);
  transition: 0.5s;
  z-index: -1;
  transform: translateY(-100%);
}

.nav__links.open {
  transform: translateY(0);
}

.nav__links a {
  font-weight: 700;
  color: var(--white);
}

.nav__links .btn {
  font-weight: 700;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 768px) {
  .nav__links .btn {
    font-weight: 700;
    padding: 0;
    background-color: transparent;
    
    padding-left: 60px;
    padding-right: 60px;
    position: center;
  }

  .nav__links a {
    font-weight: 700;
    color: var(--white);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 60px;
    padding-right: 60px;

  }
}

.nav__btns {
  display: none;
}

.navbutton_extra_space {
  margin-top: 1rem;
}

.header__container {
  display: grid;
  gap: 2rem 0;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}

.header__container::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background: var(--header-gradient);
  border-radius: 1rem 1rem 0.5rem 0.5rem;
  z-index: -1;
}

.header__content h1 {
  position: relative;
  isolation: isolate;
  margin-bottom: 2rem;
  font-size: 2.75rem;
  font-weight: 800;
  color: var(--text-dark);
  line-height: 3.25rem;
}

.header__content h1::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1rem;
  height: 4px;
  width: 2rem;
  background-color: var(--primary-color);
}

.header__content p {
  margin-bottom: 2rem;
  font-weight: 500;
  color: var(--text-dark);
  line-height: 1.75rem;
}

.header__links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header__links img {
  max-width: 125px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.steps__container :is(.section__subheader, .section__header) {
  text-align: center;
}

.steps__grid {
  margin-top: 4rem;
  display: grid;
  gap: 2rem;
}

.steps__card {
  text-align: center;
}

/* .steps__card span {
  display: inline-block;
  margin-bottom: 2rem;
  padding: 10px 17px;
  font-size: 1.75rem;
  color: var(--primary-color);
  border-radius: 5px;
  background-color: var(--extra-light);
  border: 4px solid var(--white);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
} */

.steps__card span {
  display: inline-block;
  margin-bottom: 2rem;
  padding: 14px 21px;
  font-size: 1.75rem;
  color: var(--white);
  border-radius: 5px;
  background: var(--gradient);
  border: none;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

/* .steps__card:hover span {
  padding: 14px 21px;
  background: var(--gradient);
  color: var(--white);
  border: none;
} */

.steps__card h4 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 800;
  color: var(--text-dark);
}

.steps__card p {
  color: var(--text-light);
  line-height: 1.75rem;
}

.service__container {
  display: grid;
  gap: 2rem;
  overflow: hidden;
}

/* Car Types Grid Container */
.car__types__grid {
  display: grid;
  gap: 0.5rem;
  /* Smaller gap for tighter layout */
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  /* Dynamic grid layout */
  margin-top: 1rem;
  width: 100%;
  align-items: center;
  /* Center items vertically */
  padding: 0.5rem;
  /* Added padding for better spacing */
}

/* Car Type Item */
.car__type {
  /* position: relative; */
  display: block;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  background-color: var(--extra-light);
  padding: 0.3rem;
  /* Smaller padding */
  text-align: center;
  font-size: 0.65rem;
  /* Smaller font size */
}

/* Car Type Image Container */
.car__type__image {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Car Type Image */
.car__type__image img {
  width: auto;
  max-width: 90%;
  /* Smaller max width */
  height: auto;
  object-fit: cover;
  /* Maintain aspect ratio while covering the container */
  border-radius: 4px;
  display: block;
  transition: transform 0.3s;
}

/* Car Type Image Hover Effect */
.car__type__image img:hover {
  transform: scale(1.05);
  /* Slight enlargement on hover */
}

/* Car Type Title */
.car__type__title {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-dark);
  text-align: center;
  display: block;
}

@media (max-width: 768px) {
  .header__content h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .btn {
    font-size: 0.9rem;
  }

  .steps__card span {
    font-size: 1.5rem;
  }

  .service__container {
    grid-template-columns: 1fr;
  }

  /* Adjust font size for better readability */
  .car__type__title {
    font-size: 0.8rem;
  }

  .nav__links {
    width: 80%;
    padding: 2rem;
    flex-direction: column;
    right: 0px;
    border-bottom-left-radius: 8px;
  }

  .nav__btns {
    display: none;
  }
}

@media (min-width: 768px) {
  .service__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .steps__grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .header__container {
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
  }
}

/* Dark Mode Example (Optional) */
body.dark-mode {
  background-color: var(--background-dark);
  color: var(--text-light-theme);
}


/* Responsive Adjustments for Car Types */
@media (min-width: 768px) {
  .car__types__grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    /* Adjust column size for larger screens */
  }
}

.service__list {
  margin-top: 2rem;
  display: grid;
  gap: 2rem;
}

.service__list li {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.service__list li span {
  padding: 12px 15px;
  font-size: 1.5rem;
  color: var(--primary-color);
  background-color: var(--extra-light);
  border: 4px solid var(--white);
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.service__list h4 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--text-dark);
}

.service__list p {
  color: var(--text-light);
  line-height: 1.75rem;
}

.experience__container :is(.section__subheader, .section__header) {
  text-align: center;
  max-width: 600px;
  margin-inline: auto;
}

.experience__content {
  max-width: 1024px;
  margin-inline: auto;
  margin-top: 4rem;
  position: relative;
  isolation: isolate;
}

.experience__content img {
  max-width: 300px;
  margin-inline: auto;
  opacity: 0.5;
}

.experience__card {
  position: absolute;
  max-width: 200px;
}

.experience__card span {
  display: inline-block;
  margin-bottom: 0.5rem;
  padding: 10px 13px;
  font-size: 1.5rem;
  color: var(--primary-color);
  background-color: var(--extra-light);
  border: 4px solid var(--white);
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.experience__card h4 {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-dark);
}

.experience__card:nth-child(1) {
  top: 5rem;
  left: 1rem;
}

.experience__card:nth-child(2) {
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.experience__card:nth-child(3) {
  bottom: 5rem;
  left: 1rem;
}

.experience__card:nth-child(4) {
  top: 5rem;
  right: 1rem;
  text-align: right;
}

.experience__card:nth-child(5) {
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  text-align: right;
}

.experience__card:nth-child(6) {
  bottom: 5rem;
  right: 1rem;
  text-align: right;
}

.download__container {
  overflow: hidden;
}

.download__grid {
  display: grid;
  padding: 5rem 1rem;
  background: var(--gradient);
  border-radius: 1rem;
}

.download__image {
  display: none;
}

.download__content .section__header {
  margin-bottom: 1rem;
  color: var(--white);
}

.download__content p {
  margin-bottom: 2rem;
  color: var(--extra-light);
  line-height: 1.75rem;
}

.download__links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.download__links img {
  max-width: 125px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.footer__container {
  display: grid;
  gap: 4rem 2rem;
  border-bottom: 1px solid var(--text-light);
}

.footer__col h4 {
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-dark);
}

.footer__links {
  display: grid;
  gap: 1rem;
}

.footer__links a {
  font-weight: 600;
  color: var(--text-light);
}

.footer__links a:hover {
  color: var(--primary-color);
}

.footer__bar {
  padding-block: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer__bar h4 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-dark);
}

.footer__bar p {
  font-weight: 500;
  color: var(--text-light);
  text-align: center;
}

.footer__socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__socials a {
  display: inline-block;
  padding: 8px 10px;
  font-size: 1rem;
  color: var(--text-dark);
  border-radius: 100%;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.footer__socials a:hover {
  color: var(--white);
  background: var(--gradient);
}

@media (width > 540px) {
  .steps__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width > 768px) {
  header::before {
    height: calc(100% - 4rem);
  }

  nav {
    position: static;
    padding-block: 2rem;
    padding-inline: 1rem;
    max-width: var(--max-width);
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .nav__header {
    flex: 1;
    padding: 0;
    background-color: transparent;
  }

  .nav__logo a {
    font-weight: 800;
    color: var(--primary-color);
  }

  .nav__menu__btn {
    display: block;
    /* Show the menu button by default */
    font-size: 1.5rem;
    color: var(--white);
    cursor: pointer;
    z-index: 10;
    /* Ensure it is above other elements */
  }

  .nav__links {
    position: static;
    padding: 0;
    width: fit-content;
    flex-direction: row;
    background-color: transparent;
    transform: none;
    transition: right 0.5s ease;
    /* Smooth transition for menu slide-in */
  }

  .nav__links a {
    padding-block: 5px;
    color: var(--text-dark);
    border-bottom: 2px solid transparent;
  }

  .nav__links a:hover {
    border-color: var(--primary-color);
  }

  .nav__links__btn {
    display: none;
  }

  .nav__btns {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }

  .nav__btns .btn__primary {
    color: var(--text-dark);
    background-color: transparent;
  }

  .btn__primary:hover {
    color: var(--text-light-theme);
  }

  .nav__btns .btn__tertiary {
    font-weight: 500;
    color: var(--text-dark);
    background-color: transparent;
    appearance: auto;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
  }

  .btn__tertiary:hover {
    color: var(--text-light-theme);
  }

  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .nav__menu__btn {
      display: block;
      /* Show menu button on mobile */
    }

    .nav__links {
      position: absolute;
      top: 0;
      right: -100%;
      /* Initially off-screen to the right */
      width: 60%;
      /* Adjust width as needed */
      height: 100%;
      background-color: var(--primary-color);
      padding: 2rem;
      flex-direction: column;
      align-items: center;
      transition: right 0.5s ease;
      /* Smooth transition */
      z-index: 10;
    }

    .nav__links.open {
      right: 0;
      /* Slide in from the right */
    }

    .nav__links__btn {
      display: block;
      /* Show sign-up/sign-in buttons in the mobile menu */
    }

    .nav__btns {
      display: none;
      /* Hide additional buttons on mobile */
    }
  }


  .header__container {
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
  }

  .header__container::before {
    right: 5rem;
    width: calc(50% - 4rem);
    height: 100%;
  }

  .header__image {
    grid-column: 3/6;
  }

  .header__content {
    grid-area: 1/1/2/3;
  }

  .steps__grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .service__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .experience__content img {
    opacity: 1;
  }

  .experience__card:nth-child(1) {
    left: 5rem;
  }

  .experience__card:nth-child(3) {
    left: 5rem;
  }

  .experience__card:nth-child(4) {
    right: 5rem;
  }

  .experience__card:nth-child(6) {
    right: 5rem;
  }

  .download__grid {
    margin-block: 4rem;
    padding-inline: 2rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .download__image {
    display: flex;
    position: relative;
    isolation: isolate;
  }

  .download__image img {
    position: absolute;
    max-width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.2));
  }

  .footer__container {
    grid-template-columns: repeat(4, 1fr);
  }

  .footer__bar {
    flex-direction: row;
  }

  .footer__bar :is(h4, .footer__socials) {
    flex: 1;
  }

  .footer__socials {
    justify-content: flex-end;
  }

  /* Car Type Item */
  .car__type {
    position: relative;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    background-color: var(--extra-light);
    /* Default background color */
    padding: 0.5rem;
    text-align: center;
    font-size: 0.75rem;
    /* Adjusted font size for smaller screens */
  }

  /* Hover Effect for Car Type */
  .car__type:hover {
    transform: translateY(-2px);
    /* Slight lift on hover */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    background-color: #3f5c9b;
    /* Light blue hover effect */
    color: white !important
  }

  .car__type:hover * {
    color: white !important;
    /* Force the color of all child elements to white */
  }


  /* Car Type Image Container */
  .car__type__image {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* Car Type Image */
  .car__type__image img {
    width: auto;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    /* Preserve aspect ratio while covering container */
    border-radius: 4px;
    display: block;
    transition: transform 0.3s;
  }

  /* Car Type Image Hover Effect */
  .car__type__image img:hover {
    transform: scale(1.05);
    /* Slight enlargement on hover */
  }

  /* Car Type Title */
  .car__type__title {
    margin-top: 0.3rem;
    font-size: 0.7rem;
    /* Adjusted font size for better readability */
    font-weight: 600;
    color: var(--text-dark);
    text-align: center;
  }
}

/* General Styles */
.car-card {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, background-color 0.3s, transform 0.3s;
}

/* Features Styling */
.car-card-features {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  /* Prevent wrapping */
  overflow-x: auto;
  /* Allow horizontal scrolling if needed */
  white-space: nowrap;
  /* Prevent text from breaking into multiple lines */
  padding-bottom: 8px;
  margin-bottom: 8px;
  gap: 3px;
  /* Reduced gap for tighter spacing */
}

/* Icon and Text Styling */
.car-card-features {
  margin-right: 2px;
  /* Small margin between icon and text */
}

/* Divider */
.divider {
  border-bottom: 1px solid lightgrey;
  margin: 10px 0;
}

/* Pricing and Special Features */
.car-card-details {
  display: flex;
  flex-direction: row;
  /* Default layout: side by side */
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
  min-height: 100px;
  /* Ensure dedicated space for content */
}

.special-features {
  min-height: 50px;
  /* Ensure dedicated space for content */
}

.special-features ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
  min-height: 50px;
  /* Ensure dedicated space for content */
}

.special-features li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #333;
}

.special-features li svg {
  margin-right: 5px;
  color: green;
  /* Color for checkmark */
}

/* Pricing Styling */
.car-card-pricing {
  text-align: right;
  color: #555;
  min-height: 50px;
  /* Ensure dedicated space for pricing */
}

/* Description Styling */
.car-card-description {
  margin: 16px 0;
  color: #666;
  line-height: 1.5;
  min-height: 90px;
  /* Ensure dedicated space for description */
}

/* Action Buttons */
.car-card-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 1rem;
}

.callbtn {
  flex: 1;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 80px;
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .car-card-details {
    flex-direction: column;
    /* Stack elements vertically on mobile */
    align-items: flex-start;
    /* Align items to the left */
  }

  .car-card-features {
    justify-content: flex-start;
    /* Align features to the left */
  }

  .car-card-pricing {
    text-align: left;
    /* Align text to the left */
    margin-top: 8px;
    /* Add some margin above for spacing */
  }

  .special-features ul {
    margin-bottom: 16px;
    /* Add some spacing below the special features */
  }

  .car-card {
    padding: 12px;
    /* Reduce padding for smaller screens */
    margin: 16px 0;
    /* Increase vertical margin for better spacing */
  }

  .callbtn {
    font-size: 0.8rem;
    /* Slightly smaller font size for mobile */
    padding: 0.4rem 0.8rem;
    /* Adjust padding for smaller buttons */
  }
}

/* Thumbnail Styles */
.car-card-photos {
  display: flex;
  gap: 8px;
  margin-bottom: 1rem;
  padding-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  position: relative;
  scroll-snap-type: mandatory;
}

/* Thumbnails Container in Lightbox */
.lightbox-thumbnails {
  position: absolute;
  bottom: 20px;
  /* Position at the bottom of the lightbox */
  left: 50%;
  transform: translateX(-50%);
  /* Center horizontally */
  display: flex;
  gap: 8px;
  /* Space between thumbnails */
  align-items: center;
  justify-content: center;
  z-index: 1002;
  /* Ensure above other elements */
}

/* Thumbnails */
.lightbox-thumbnail {
  width: 100px;
  /* Small size for thumbnails */
  height: auto;
  /* Maintain aspect ratio */
  cursor: pointer;
  border: 4px solid transparent;
  transition: border-color 0.2s;
  overflow-x: auto;
  scroll-behavior: auto;

}

.lightbox-thumbnail.active {
  border-color: #007bff;
  /* Highlight active thumbnail */
}

@media (max-width: 786px) {
  .lightbox-thumbnail.active {
    border-color: #007bff;
    /* Highlight active thumbnail */
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: auto;

  }
}

/* Individual Photo Container */
.photo-container {
  flex: 0 0 auto;
  /* Prevent flex items from shrinking or growing */
  width: auto;
  /* Set auto width to adjust to content */
  max-width: 100%;
  /* Prevent overflow beyond container */
  position: relative;
  cursor: pointer;
  aspect-ratio: 4 / 3;
  /* Maintain a consistent aspect ratio */
  overflow: hidden;
  /* Ensure any overflow is hidden */
  display: inline-block;
  /* Align contents properly */
  align-items: center;
  justify-content: center;
}

/* Car Photo Styles */
.car-photo {
  width: 100%;
  /* Fill the container width */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensure the photo covers the container while preserving aspect ratio */
  border-radius: 4px;
  transition: transform 0.3s;
}

/* Hover Effect on Car Photo */
.car-photo:hover {
  transform: scale(1.05);
  /* Enlarge on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .car-listing {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .car-card-photos {
    overflow-x: auto;
    /* Ensure horizontal scrolling */
    overflow-y: hidden;
    /* Hide vertical overflow */
  }
}

@media (min-width: 769px) {
  .car-listing {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .car-card-photos:hover {
    overflow-x: auto;
  }
}

.call {
  background-color: #007bff;
}

.whatsapp {
  background-color: #25d366;
}

.email {
  background-color: #6c757d;
}

/* Lightbox Styles */

.lightbox-image {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}

.lightbox-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;
}

.lightbox-arrow.left {
  left: 20px;
}

.lightbox-arrow.right {
  right: 20px;
}

.lightbox-arrow:hover {
  color: #ddd;
}

/* Close Button for Lightbox */
.lightbox-close {
  position: absolute;
  top: 20px;
  /* Position at the top */
  right: 20px;
  /* Position on the right */
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1003;
  /* Ensure it is above other elements */
  transition: color 0.3s;
}

.lightbox-close:hover {
  color: #ddd;
  /* Light hover effect */
}

/* Rest of the Lightbox Styles */
.custom-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  /* Prevent overflow issues */
}


/* Styling for the photo container */
.photo-container {
  position: relative;
  /* Enable positioning of child elements */
  display: inline-block;
  /* Ensure the container wraps around the image */
}

/* Lightbox Styles */
.custom-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
}

/* "Find Out More" Button Styling in Lightbox */
.lightbox-more-details-button {
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Center the button exactly in the middle */
  background-color: rgba(98, 165, 209, 0.248);
  /* Transparent background */
  color: rgb(230, 230, 230);
  /* White text */
  font-weight: bold;
  /* Make the text bold */
  padding: 12px 24px;
  /* Padding around the button */
  text-decoration: none;
  /* Remove underline */
  border: 1.5px solid white;
  /* White border */
  border-radius: 20px;
  /* Rounded corners */
  font-size: 1.2rem;
  /* Increase font size for better visibility */
  z-index: 1001;
  /* Ensure the button is above the image */
  text-align: center;
  /* Center text inside the button */
  transition: transform 0.3s, border-color 0.3s, color 0.3s;
  /* Smooth transition for hover effects */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  /* Subtle shadow for visibility */
}

/* Darken the 4th image when opened in lightbox */
.lightbox-image.darken {
  filter: brightness(0.5);
  /* Darken the image */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .car-listing {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .car-card-photos {
    overflow-x: auto;
  }
}

@media (min-width: 769px) {
  .car-listing {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .car-card-photos:hover {
    overflow-x: auto;
  }
}

/* Similar Cars Section */
.similar-cars-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 20px;
}

.similar-car-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 10px;
}

.similar-car-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.similar-car-photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}

.similar-car-info {
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .similar-cars-container {
    grid-template-columns: repeat(2, 1fr);
    /* Adjust for smaller screens */
  }
}

@media (max-width: 480px) {
  .similar-cars-container {
    grid-template-columns: 1fr;
    /* Stacks for very small screens */
  }
}

.callbtn {
  flex: 1 1 auto;
  /* Flex-grow to fit available space */
  padding: 0.5rem 1rem;
  /* Add padding for better button size */
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 80px;
  /* Minimum width for better visibility */
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap;
  /* Prevents text from breaking into multiple lines */
}

.callbtn.call {
  background-color: #3f5c9b;
  /* Blue color for call button */
}

.callbtn.whatsapp {
  background-color: #25d366;
  /* Green color for WhatsApp button */
}

.callbtn.telegram {
  background-color: #24A1De;
  /* Grey color for email button */
}

.callbtn:hover {
  transform: scale(1.05);
  /* Slight enlargement on hover */
  opacity: 0.9;
  /* Slight fade effect on hover */
}

.callbtn:focus {
  outline: none;
  /* Remove outline when button is focused */
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  /* Add focus shadow */
}

.car-card-actions {
  display: flex;
  justify-content: space-between;
  /* Space out the buttons */
  gap: 10px;
  /* Add spacing between buttons */
  margin-top: 1rem;
  /* Add some margin on top for spacing */
}

.car-details-container {
  max-width: 1200px;
  margin: 0 auto;
}

.car-details-header {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 40px;
  /* margin-left: 20px; */
}

.search-page-container {
  padding-top: 40px;
}

.car-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--text-dark);
}

.car-subtitle {
  font-size: 1.2rem;
  color: var(--text-light);
}

.car-details-columns {
  display: flex;
  flex-wrap: wrap;
  /* Ensure it wraps on smaller screens */
  justify-content: space-between;
  gap: 20px;
}

.car-info,
.car-pricing {
  flex: 1;
  padding: 20px;
  margin-bottom: 20px;
  /* Add margin-bottom to separate sections */
}

.car-pricing {
  max-width: 300px;
  width: 100%;
  /* Make sure it adapts to different screen sizes */
}

.car-card-features {
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--text-dark);
}

.icon {
  font-size: 20px;
  /* Default size */
}

@media (max-width: 768px) {
  .car-card-features {
    font-size: 1rem;
    /* Keep the text size the same */
  }

  .icon {
    font-size: 15px;
    /* Larger size on mobile */
  }
}

.car-price-day {
  font-size: 1.2rem;
  margin: 10px 0;
  font-weight: bold;
  /* Make the price bold */
  text-align: right;
  /* Align price text to the right */
}

.contact-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1rem;
  color: white;
  background-color: var(--primary-color);
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #555;
}

.similar-cars-section {
  margin-top: 30px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .car-details-columns {
    flex-direction: column;
    /* Stack columns on smaller screens */
    gap: 20px;
  }

  .car-pricing {
    max-width: 100%;
    /* Full width on smaller screens */
  }

  .car-image {
    max-width: 100%;
    /* Make images responsive */
  }
}

.see-all-photos-btn {
  display: block;
  margin: 10px auto;
  padding: 8px 16px;
  font-size: 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.see-all-photos-btn:hover {
  background-color: #3c4286;
}

.divider {
  border-top: 1px solid #ddd;
  margin: 20px 0;
}


/* Larger images for bigger screens */
@media (min-width: 1024px) {
  .car-image-collage-item {
    width: 100%;
    height: 350px;
    /* Consistent height for all images */
    object-fit: cover;
    /* Ensure the image covers the container */
  }

  .car-image-collage {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Ensure 5 images are in one row */
    grid-gap: 0;
    /* No gap between images */
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
  }

  .car-image-collage-item {
    width: 100%;
    object-fit: cover;
    /* Cover the entire container without distortion */
  }
}

/* Mobile-specific styles for scrolling experience */
@media (max-width: 768px) {
  .car-image-collage {
    display: flex;
    /* Flexbox for horizontal scrolling */
    overflow-x: scroll;
    /* Enable horizontal scrolling */
    gap: 5px;
    scroll-snap-type: x mandatory;
    /* Activate snap scrolling */
    scroll-behavior: smooth;
    /* Enable smooth scrolling */
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .car-image-collage-item {
    flex: 0 0 80%;
    /* Show one full image and part of the next to indicate more images */
    height: 200px;
    /* Smaller height on mobile */
    scroll-snap-align: start;
    /* Ensures image snapping when scrolling */
  }

  /* Optional: Show scrollbar hint */
  .car-image-collage::-webkit-scrollbar {
    height: 8px;
    /* Height of the scrollbar */
  }

  .car-image-collage::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    /* Style the scrollbar thumb */
    border-radius: 10px;
  }

  .car-image-collage::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    /* Style the scrollbar track */
  }

  /* Lightbox thumbnails */
  .lightbox-thumbnails-wrapper {
    overflow-x: auto;
    white-space: nowrap;
  }

  .lightbox-thumbnails img {
    display: inline-flex;
    margin-right: 5px;
    width: 75px;
    /* Adjust thumbnail size */
    height: auto;
    cursor: pointer;
  }

  .lightbox-thumbnail.active {
    border-color: #007bff;
    /* Highlight active thumbnail */
    outline: 2px solid #007bff;
    /* Add an outline for clarity */
  }
}


.pricing-card {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #ffffff;
  max-width: 500px;
  max-height: 1000px;
}

.pricing-tabs {
  display: flex;
  margin-bottom: 15px;

}

.pricing-tab {
  padding: 5 10;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: large;
}

.pricing-tab.active {
  background-color: #fff;
  color: var(--primary-color);
  border-bottom: 2px solid #3f5c9b;
}

.pricing-info ul {
  list-style: none;
  padding-left: 0;
  /* Remove default padding */
  margin: 0;
}

.pricing-info li {
  padding: 8px 0;
  /* Add vertical padding for space between items */
  margin-bottom: 4px;
  /* Small margin for more spacing */
  border-bottom: 1px solid #e0e0e0;
  /* Light gray line between items */
}

.pricing-info li:last-child {
  border-bottom: none;
  /* Remove the line from the last item */
}

.pricing-info {
  padding: 16px;
}

.contact-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .pricing-tab {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 18px;
    color: var(--text-dark);
  }
}

/* Parent container with flexbox */
.parent-container {
  display: flex;
  flex-wrap: nowrap;
  /* Don't wrap the items, keep them in one line */
  gap: 20px;
  /* Optional: spacing between columns */
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
  margin-bottom: 2rem;
}

.car-description {
  margin-bottom: 2rem;
}

/* Left side (2/3) */
.two-third {
  flex: 0 0 66.6667%;
  /* 2/3 width */
  max-width: 66.6667%;
  /* Prevent expanding beyond 2/3 */
  box-sizing: border-box;
  /* Include padding and border in width */
  margin: 0px;
  padding: 0%;
}

/* Right side (1/3) */
.one-third {
  flex: 0 0 33.3333%;
  /* 1/3 width */
  max-width: 33.3333%;
  /* Prevent expanding beyond 1/3 */
  box-sizing: border-box;
  box-shadow: 0 0 4px #c7c7c7;
  border: 3px;
  border-radius: 8px;
  padding: 0%
}

/* Responsive layout for mobile */
@media (max-width: 768px) {
  .parent-container {
    flex-direction: column;
    /* Stack the elements vertically on mobile */
  }

  .one-third,
  .two-third {
    flex: 0 0 100%;
    /* Make both divs take full width on small screens */
    max-width: 100%;
    /* Ensure they expand to full width */
  }

  .one-third {
    order: -1;
  }

  .read-more-btn {
    display: none;
    /* Default hidden for larger screens */
  }

  .mobile-description {
    max-height: none;
    /* Full description on larger screens */
    overflow: visible;
  }

  /* Make the description collapsible */
  .mobile-description {
    max-height: 100px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    color: var(--text-light);
    font-weight: 400;
    font-size: .9rem;
    font-weight: 400;
  }

  .mobile-description.expanded {
    max-height: none;
  }
}

@media (min-width: 1025px) {
  .car-image-collage {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    gap: 10px;
    /* grid-auto-rows: 160px; */
    margin-bottom: 2rem;
  }

  .car-image-collage-item:nth-child(n+5) {
    display: none;
    /* Hide images after the 5th image */
  }
}

.car-special-features {
  padding-top: 15px;
}

.car-special-features ul {
  list-style-type: none;
  /* Remove default bullets */
  padding: 0;
}

.car-special-features li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  /* Spacing between items */
}

.feature-check {
  color: green;
  /* Set the color for the checkmark */
  margin-right: 8px;
  /* Space between the checkmark and the text */
}

/* Read More button */
.read-more-btn {
  display: none;
}

@media (max-width: 768px) {

  /* Make description collapsible */
  .read-more-btn {
    display: inline-block;
    background-color: transparent;
    color: var(--text-light);
    border-bottom: var(--primary-color) solid 2px;
    cursor: pointer;
  }

  .car-title {
    font-size: 18px;
  }

  .car-subtitle {
    font-size: 12px;
  }

  .pricing-info li {
    font-size: 14px;
  }
}

h3 {
  color: var(--text-dark)
}

p {
  color: var(--text-light);
  line-height: 1.5
}

@media (max-width: 768px) {
  p {
    color: var(--text-dark);
    line-height: 1.5;
    size: 18px;
    font-weight: 650;
  }
}

.pagination-controls {
  margin-top: 20px;
}

.pagination-button {
  margin: 0 2px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  border-radius: 8px;
}

.pagination-button.active {
  background-color: var(--primary-color);
  color: white;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.heart {
  position: absolute;
  top: 75px; /* Position relative to the photo container */
  right: 24px;
  background: rgba(137, 137, 137, 0.363);
  font-size: 16px;
  cursor: pointer;
  transition: color 0.1s ease;
  z-index: 5;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 8px;
}

.heart.liked {
  color: var(--primary-color); /* Color when liked */
}

.heart-icon {
  font-size: 24px; /* Adjust icon size */
  color: #fefefe;
  transition: color 0.1s ease;
  font-size: 18px;
}

.heart.liked .heart-icon {
  color: var(--primary-color); /* Change heart color when liked */
}