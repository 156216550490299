.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000; /* Ensure modal overlay is above all other content */
    pointer-events: auto; /* Allow interactions within the modal */
    isolation: isolate;
}

.modal-content {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    position: relative;
    z-index: 10001; /* Ensure modal content is above overlay itself */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    background: #d3d3d3;
    border-radius: 50px;
    justify-content: center;
}

.modal-close {
    width: 30px;
    height: 30px;
    font-size: 23px;
    background: #a0a5b3;
    display: flex;
    border-radius: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: 15px;
    margin-top: 5px;
}

.modal-close:hover {
    background: var(--primary-color);
}


/* Body overflow control for modal open */
body.modal-open {
    overflow: hidden; /* Prevent scrolling in the background when modal is open */
}

.modal-overlay,
.modal-content {
    transform: translateZ(0);
}