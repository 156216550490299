@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");


:root {
  --primary-color: #3f5c9b;
  --text-dark: #2E3E4E;
  --text-light: #475569;
  --extra-light: #f2f2f2;
  --white: #ffffff;
  --max-width: 1200px;
  --gradient: linear-gradient(to bottom, #3f5c9b, #5a5c6f);
  --header-gradient: linear-gradient(to bottom, #3f5c9b, #3f5c9b, #5a5c6f);

  /* Theme colors for dark mode or additional themes */
  --background-dark: #121212;
  --text-light-theme: #f2f2f2;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 1.5rem 1rem;
}

.section__subheader {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-light);
  letter-spacing: 1px;
}

.section__header {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--text-dark);
}

@media (max-width: 768px) {
  .section__header {
    font-size: 1.5rem;
  }

  .section__subheader {
    font-size: 1rem;
  }
}

.btn {
  padding: 0.75rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: var(--white);
  background-color: var(--primary-color);
  white-space: nowrap;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
}

.btn:hover {
  background-color: #3c4286;
}

.btn.isLoading {
  background-color: #3c4286;
  cursor: not-allowed; /* Change cursor to indicate loading */
}

.contact_us_6 * {
    font-family: "Mulish", sans-serif;
  }
  
  .contact_us_6 .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 1.5rem;
  }
  
  .contact_us_6 .responsive-cell-block {
    min-height: 75px;
  }
  
  .contact_us_6 input:focus,
  .contact_us_6 textarea:focus {
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
  }
  
  .contact_us_6 .container-block {
    min-height: 75px;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: block;
  }
  
  .contact_us_6 .responsive-container-block {
    min-height: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .contact_us_6 .responsive-container-block.big-container {
    padding-top: 10px;
    padding-right: 30px;
    width: 35%;
    padding-bottom: 10px;
    padding-left: 30px;
    background-color: #03a9f4;
    position: absolute;
    height: 950px;
    right: 0px;
  }
  
  .contact_us_6 .responsive-container-block.container {
    position: relative;
    min-height: 75px;
    flex-direction: row;
    z-index: 2;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    max-width: 1320px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  
  .contact_us_6 .container-block.form-wrapper {
    background-color: white;
    max-width: 450px;
    text-align: center;
    padding-top: 50px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-top: 90px;
    margin-right: 0px;
    margin-bottom: 60px;
    margin-left: 0px;
  }
  
  .contact_us_6 .text-blk.contactus-head {
    font-size: 2.5rem;
    line-height: 52px;
    color: var(--text-dark);
    font-weight: 800;
  }
  
  .contact_us_6 .text-blk.contactus-subhead {
    color: var(--text-light);
    width: 300px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    display: none;
  }
  
  .contact_us_6 .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 26px;
    margin-left: 0px;
    min-height: 50px;
  }
  
  .contact_us_6 .input {
    width: 100%;
    height: 50px;
    padding-top: 1px;
    padding-right: 15px;
    padding-bottom: 1px;
    padding-left: 15px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #eeeeee;
    border-right-color: #eeeeee;
    border-bottom-color: #eeeeee;
    border-left-color: #eeeeee;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    font-size: 16px;
    color: black;
  }
  
  .contact_us_6 .textinput {
    width: 98%;
    min-height: 150px;
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #eeeeee;
    border-right-color: #eeeeee;
    border-bottom-color: #eeeeee;
    border-left-color: #eeeeee;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    font-size: 16px;
  }
  
  .contact_us_6 .btn {
    width: 98%;
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: outset;
    border-right-style: outset;
    border-bottom-style: outset;
    border-left-style: outset;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 12px;
  }
  
  .contact_us_6 .form-box {
    z-index: 2;
    margin-top: 0px;
    margin-right: 48px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .contact_us_6 .text-blk.input-title {
    text-align: left;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    font-size: 14px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: var(--text-light)
  }
  
  .contact_us_6 ::placeholder {
    color: #dadada;
  }
  
  .contact_us_6 .mob-text {
    display: block;
    text-align: left;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 25px;
    margin-left: 0px;
  }
  
  .contact_us_6 .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-12.wk-ipadp-12 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  
  .contact_us_6 .text-blk.contactus-subhead.color {
    color: white;
  }
  
  .contact_us_6 .map-box {
    max-width: 800px;
    max-height: 520px;
    width: 100%;
    height: 520px;
    /* background-color: #d9d9d9;
    background-image: url("https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/sc23.png"); */
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
  }
  
  .contact_us_6 .map-part {
    width: 100%;
    height: 100%;
  }
  
  .contact_us_6 .text-blk.map-contactus-head {
    font-size: 22px;
    line-height: 32px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    color: var(--primary-color);
  }
  
  .contact_us_6 .text-blk.map-contactus-subhead {
    max-width: 300px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  
  .contact_us_6 .social-media-links.mob {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    width: 230px;
    display: flex;
    justify-content: flex-start;
  }
  
  .contact_us_6 .link-img {
    width: 30px;
    height: 30px;
    margin-top: 0px;
    margin-right: 25px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .contact_us_6 .link-img.image-block {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .contact_us_6 .social-icon-link {
    margin: 0 25px 0 0;
    padding: 0 0 0 0;
  }
  
  @media (max-width: 1024px) {
    .contact_us_6 .responsive-container-block.container {
      justify-content: center;
    }
  
    .contact_us_6 .map-box {
      position: absolute;
      top: 0px;
      max-height: 320px;
    }
  
    .contact_us_6 .map-box {
      max-width: 100%;
      width: 100%;
    }
  
    .contact_us_6 .responsive-container-block.container {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .contact_us_6 .map-part {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .contact_us_6 .container-block.form-wrapper {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .mob-text {
      display: block;
    }
  
    .contact_us_6 .form-box {
      margin-top: 200px;
      margin-right: 60px;
      margin-bottom: 40px;
      margin-left: 0px;
    }
  
    .contact_us_6 .link-img {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      justify-content: space-evenly;
    }
  
    .contact_us_6 .social-media-links.mob {
      justify-content: space-evenly;
    }
  
    .contact_us_6 .responsive-cell-block.wk-desk-7.wk-ipadp-12.wk-tab-12.wk-mobile-12 {
      text-align: center;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
    }
  
    .contact_us_6 .text-blk.contactus-subhead {
      display: block;
    }
  
    .contact_us_6 .mob-text {
      text-align: center;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .responsive-container-block.container {
      flex-wrap: wrap;
    }
  
    .contact_us_6 .form-box {
      margin-top: 200px;
      margin-right: 0px;
      margin-bottom: 40px;
      margin-left: 0px;
    }
  }
  
  @media (max-width: 768px) {
    .contact_us_6 .btn {
      width: 100%;
    }
  
    .contact_us_6 .input {
      width: 100%;
    }
  
    .contact_us_6 .textinput {
      width: 100%;
    }
  
    .contact_us_6 .container-block.form-wrapper {
      margin-top: 80px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .text-blk.input-title {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .contact_us_6 .form-box {
      padding-top: 0px;
      padding-right: 20px;
      padding-bottom: 0px;
      padding-left: 20px;
    }
  
    .contact_us_6 .container-block.form-wrapper {
      padding-top: 50px;
      padding-right: 15px;
      padding-bottom: 50px;
      padding-left: 15px;
    }
  
    .contact_us_6 .mob-text {
      display: block;
    }
  
    .contact_us_6 .responsive-container-block.container {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .contact_us_6 .form-box {
      margin-top: 200px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .container-block.form-wrapper {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .form-box {
      margin-top: 220px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .form-box {
      margin-top: 220px;
      margin-right: 0px;
      margin-bottom: 50px;
      margin-left: 0px;
    }
  
    .contact_us_6 .text-blk.contactus-head {
      font-size: 32px;
      line-height: 40px;
    }
  }
  
  @media (max-width: 500px) {
    .contact_us_6 .container-block.form-wrapper {
      padding-top: 50px;
      padding-right: 15px;
      padding-bottom: 50px;
      padding-left: 15px;
    }
  
    .contact_us_6 .container-block.form-wrapper {
      margin-top: 60px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .contact_us_6 .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 0px;
    }
  
    .contact_us_6 .responsive-container-block {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 35px;
      margin-left: 0px;
    }
  
    .contact_us_6 .text-blk.input-title {
      font-size: 12px;
    }
  
    .contact_us_6 .text-blk.contactus-head {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 800;
    }
  
    .contact_us_6 .input {
      height: 45px;
    }
  }
  
  
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap');
  
  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .wk-desk-1 {
    width: 8.333333%;
  }
  
  .wk-desk-2 {
    width: 16.666667%;
  }
  
  .wk-desk-3 {
    width: 25%;
  }
  
  .wk-desk-4 {
    width: 33.333333%;
  }
  
  .wk-desk-5 {
    width: 41.666667%;
  }
  
  .wk-desk-6 {
    width: 50%;
  }
  
  .wk-desk-7 {
    width: 58.333333%;
  }
  
  .wk-desk-8 {
    width: 66.666667%;
  }
  
  .wk-desk-9 {
    width: 75%;
  }
  
  .wk-desk-10 {
    width: 83.333333%;
  }
  
  .wk-desk-11 {
    width: 91.666667%;
  }
  
  .wk-desk-12 {
    width: 100%;
  }
  
  @media (max-width: 1024px) {
    .wk-ipadp-1 {
      width: 8.333333%;
    }
  
    .wk-ipadp-2 {
      width: 16.666667%;
    }
  
    .wk-ipadp-3 {
      width: 25%;
    }
  
    .wk-ipadp-4 {
      width: 33.333333%;
    }
  
    .wk-ipadp-5 {
      width: 41.666667%;
    }
  
    .wk-ipadp-6 {
      width: 50%;
    }
  
    .wk-ipadp-7 {
      width: 58.333333%;
    }
  
    .wk-ipadp-8 {
      width: 66.666667%;
    }
  
    .wk-ipadp-9 {
      width: 75%;
    }
  
    .wk-ipadp-10 {
      width: 83.333333%;
    }
  
    .wk-ipadp-11 {
      width: 91.666667%;
    }
  
    .wk-ipadp-12 {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .wk-tab-1 {
      width: 8.333333%;
    }
  
    .wk-tab-2 {
      width: 16.666667%;
    }
  
    .wk-tab-3 {
      width: 25%;
    }
  
    .wk-tab-4 {
      width: 33.333333%;
    }
  
    .wk-tab-5 {
      width: 41.666667%;
    }
  
    .wk-tab-6 {
      width: 50%;
    }
  
    .wk-tab-7 {
      width: 58.333333%;
    }
  
    .wk-tab-8 {
      width: 66.666667%;
    }
  
    .wk-tab-9 {
      width: 75%;
    }
  
    .wk-tab-10 {
      width: 83.333333%;
    }
  
    .wk-tab-11 {
      width: 91.666667%;
    }
  
    .wk-tab-12 {
      width: 100%;
    }
  }
  
  @media (max-width: 500px) {
    .wk-mobile-1 {
      width: 8.333333%;
    }
  
    .wk-mobile-2 {
      width: 16.666667%;
    }
  
    .wk-mobile-3 {
      width: 25%;
    }
  
    .wk-mobile-4 {
      width: 33.333333%;
    }
  
    .wk-mobile-5 {
      width: 41.666667%;
    }
  
    .wk-mobile-6 {
      width: 50%;
    }
  
    .wk-mobile-7 {
      width: 58.333333%;
    }
  
    .wk-mobile-8 {
      width: 66.666667%;
    }
  
    .wk-mobile-9 {
      width: 75%;
    }
  
    .wk-mobile-10 {
      width: 83.333333%;
    }
  
    .wk-mobile-11 {
      width: 91.666667%;
    }
  
    .wk-mobile-12 {
      width: 100%;
    }*,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
  .wk-desk-1 {
    width: 8.333333%;
  }
  
  .wk-desk-2 {
    width: 16.666667%;
  }
  
  .wk-desk-3 {
    width: 25%;
  }
  
  .wk-desk-4 {
    width: 33.333333%;
  }
  
  .wk-desk-5 {
    width: 41.666667%;
  }
  
  .wk-desk-6 {
    width: 50%;
  }
  
  .wk-desk-7 {
    width: 58.333333%;
  }
  
  .wk-desk-8 {
    width: 66.666667%;
  }
  
  .wk-desk-9 {
    width: 75%;
  }
  
  .wk-desk-10 {
    width: 83.333333%;
  }
  
  .wk-desk-11 {
    width: 91.666667%;
  }
  
  .wk-desk-12 {
    width: 100%;
  }
  
  @media (max-width: 1024px) {
    .wk-ipadp-1 {
      width: 8.333333%;
    }
  
    .wk-ipadp-2 {
      width: 16.666667%;
    }
  
    .wk-ipadp-3 {
      width: 25%;
    }
  
    .wk-ipadp-4 {
      width: 33.333333%;
    }
  
    .wk-ipadp-5 {
      width: 41.666667%;
    }
  
    .wk-ipadp-6 {
      width: 50%;
    }
  
    .wk-ipadp-7 {
      width: 58.333333%;
    }
  
    .wk-ipadp-8 {
      width: 66.666667%;
    }
  
    .wk-ipadp-9 {
      width: 75%;
    }
  
    .wk-ipadp-10 {
      width: 83.333333%;
    }
  
    .wk-ipadp-11 {
      width: 91.666667%;
    }
  
    .wk-ipadp-12 {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .wk-tab-1 {
      width: 8.333333%;
    }
  
    .wk-tab-2 {
      width: 16.666667%;
    }
  
    .wk-tab-3 {
      width: 25%;
    }
  
    .wk-tab-4 {
      width: 33.333333%;
    }
  
    .wk-tab-5 {
      width: 41.666667%;
    }
  
    .wk-tab-6 {
      width: 50%;
    }
  
    .wk-tab-7 {
      width: 58.333333%;
    }
  
    .wk-tab-8 {
      width: 66.666667%;
    }
  
    .wk-tab-9 {
      width: 75%;
    }
  
    .wk-tab-10 {
      width: 83.333333%;
    }
  
    .wk-tab-11 {
      width: 91.666667%;
    }
  
    .wk-tab-12 {
      width: 100%;
    }
  }
  
  @media (max-width: 500px) {
    .wk-mobile-1 {
      width: 8.333333%;
    }
  
    .wk-mobile-2 {
      width: 16.666667%;
    }
  
    .wk-mobile-3 {
      width: 25%;
    }
  
    .wk-mobile-4 {
      width: 33.333333%;
    }
  
    .wk-mobile-5 {
      width: 41.666667%;
    }
  
    .wk-mobile-6 {
      width: 50%;
    }
  
    .wk-mobile-7 {
      width: 58.333333%;
    }
  
    .wk-mobile-8 {
      width: 66.666667%;
    }
  
    .wk-mobile-9 {
      width: 75%;
    }
  
    .wk-mobile-10 {
      width: 83.333333%;
    }
  
    .wk-mobile-11 {
      width: 91.666667%;
    }
  
    .wk-mobile-12 {
      width: 100%;
    }
  }
  
  }

  .input-error {
    border-color: red;
  }
  
  .error-text {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.25rem;
    text-align: left;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
  }
  
  .contact-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  .contact-link {
    color: var(--text-dark);
    font-weight: 600;
    text-decoration: none;
  }
  
  .contact-link:hover {
    color: var(--primary-color);
  }

  .success-message {
    color: green;
    font-size: 0.9em; /* Makes the font slightly smaller */
    margin-top: 10px;
}