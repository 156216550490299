/* Form container */
.form {
  background: var(--white);
  padding: 20px;
  max-width: 350px;
  margin: 20px auto;
  border-radius: 4px;
  color: var(--text-dark);
}

.tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px;
  display: flex;
  justify-content: center;
}

.tab-group li {
  width: 50%;
}

.tab-group li a {
  display: block;
  padding: 15px;
  background: #a0a5b3;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.tab-group .active a {
  background: var(--primary-color);
  color: #ffffff;
}

.field-wrap {
  position: relative;
  margin-bottom: 40px;
}

.field-wrap input {
  font-size: 18px;
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: 1px solid #a0b3b0;
  color: var(--text-light);
  transition: border-color 0.25s ease;
  border-radius: 4px;
  font-size: 14px
}

.field-wrap input:focus {
  border: 1.5px solid var(--primary-color);
  outline: none;
}

.button {
  display: block;
  width: 100%;
  padding: 12px;
  background: #a0a5b3;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 10px;
  border-radius: 4px;
}

.button:hover {
  background: var(--primary-color);
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285F4;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  gap: 5px;
}

.google-signin-button:hover {
  background-color: #357ae8;
}

.google-signin-button img {
  margin-right: 8px;
  /* Ensures space between icon and text */
  width: 10%;
}

.google-signin-button span {
  /* Keeps text centered and aligned with the icon */
  display: inline-block;
  vertical-align: middle;
}

.forgot-password {
  cursor: pointer;
  color: var(--primary-color);
  font-size: 0.9rem;
  text-align: right;
  margin-top: 5px;
}

.reset-message {
  color: green;
  font-size: 0.85rem;
}

.reset-message {
  color: green;
  font-size: 0.85rem;
}

.success-message {
  color: green;
  font-size: 0.9em; /* Makes the font slightly smaller */
  margin-top: 10px;
}